import { FetchResponse } from "@icasdigital/icas.core.reactcomponents";

//TODO: move to fetch reponse and expand
export const responseCodeIsUnauth = (response: FetchResponse): boolean => {
	return response.status === 401;
};

export const responseCodeIsError = (response: FetchResponse): boolean => {
	return !responseCodeIsUnauth(response) && !response.ok;
};
