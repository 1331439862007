import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { App } from "./App";
import { AuthProvider } from "@icasdigital/icas.core.reactcomponents";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
	<Router>
		<AuthProvider>
			<App />
		</AuthProvider>
	</Router>
);
