import { LoadingStatus } from "@icasdigital/icas.core.reactcomponents";
import React, { FC, useEffect, useState } from "react";
import { customErrorInfo } from "../../types/CustomErrorInfo";
import { SaveInfo } from "../../types/SaveInfo";
import "./SubmissionInfoBarStyle.css";

type SubmissionInfoBarProps = {
	invalidSubmit: boolean;
	saveInfo: SaveInfo;
	errorInfo?: customErrorInfo;
	successMessage?: string;
};

const renderInvalidMessage = () => {
	return (
		<div className="submission-info__outer">
			<div className="submission-info-container">
				<div className="submission-info submission-info-invalid">
					{" "}
					Please check details and try again. You'll find more info highlighted
					on the form
				</div>
			</div>
		</div>
	);
};

const renderSuccessMessage = (successMessage?: string) => {
	return (
		<div className="submission-info__outer">
			<div className="submission-info-container">
				<div className="submission-info submission-info-valid">
					{successMessage ?? "Changes saved"}
				</div>
			</div>
		</div>
	);
};

const renderFailureMessage = (errorMessage?: string) => {
	const message = errorMessage
		? `${errorMessage}. Please try again or contact ICAS`
		: `An error has occured. Please try again or contact ICAS`;
	return (
		<div className="submission-info__outer">
			<div className="submission-info-container">
				<div className="submission-info submission-info-invalid">{message}</div>
			</div>
		</div>
	);
};

const renderLoadingMessage = () => {
	return (
		<div className="submission-info__outer">
			<div className="submission-info-container">
				<div className="submission-info submission-info-saving">
					Saving changes...
				</div>
			</div>
		</div>
	);
};

const useFeedbackInterval = (invalidSubmit: boolean, saveInfo: SaveInfo) => {
	const [feedbackType, setFeedbackType] = useState<
		"Invalid" | "Success" | "Loading" | "Failure" | undefined
	>();
	const { saveSent, saveState } = saveInfo;
	const [showMessage, setShowMessage] = useState(false);

	useEffect(() => {
		if (invalidSubmit) {
			setFeedbackType("Invalid");
		}
	}, [invalidSubmit]);

	useEffect(() => {
		const clearMessage = () => {
			setShowMessage(false);
			setFeedbackType(undefined);
		};
		if (feedbackType) {
			setShowMessage(true);
			if (feedbackType !== "Loading") {
				const timer = setTimeout(() => clearMessage(), 5000);
				return () => clearTimeout(timer);
			}
		}
	}, [feedbackType]);

	useEffect(() => {
		if (saveSent) {
			if (saveState === LoadingStatus.LoadedSuccessfully) {
				setFeedbackType("Success");
			} else if (saveState === LoadingStatus.IsLoading) {
				setFeedbackType("Loading");
			} else if (saveState === LoadingStatus.EndedWithError) {
				setFeedbackType("Failure");
			}
		} else {
			setFeedbackType(undefined);
		}
	}, [saveSent, saveState]);

	return { showMessage, feedbackType };
};

export const SubmissionInfoBar: FC<SubmissionInfoBarProps> = ({
	invalidSubmit,
	saveInfo,
	errorInfo,
	successMessage,
}) => {
	const { showMessage, feedbackType } = useFeedbackInterval(
		invalidSubmit,
		saveInfo
	);

	if (showMessage && feedbackType) {
		if (feedbackType === "Loading") {
			return renderLoadingMessage();
		} else if (feedbackType === "Invalid") {
			return renderInvalidMessage();
		} else if (feedbackType === "Success") {
			return renderSuccessMessage(successMessage);
		} else if (feedbackType === "Failure") {
			return renderFailureMessage(errorInfo?.errorMessage);
		} else {
			return <></>;
		}
	} else {
		return <></>;
	}
};
