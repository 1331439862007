import { ThankYou } from "../components/cards/ThankYou";
import { FeedbackCard } from "../components/cards/Feedback/FeedbackCard";

export const CompleteContainer= () => {

	return (
		<div className="app-container">
			<ThankYou />
			<FeedbackCard />
		</div>
	);
};
