import {
	AuthContext,
	FetchHelper,
	FetchResponse,
	LoadingStatus,
} from "@icasdigital/icas.core.reactcomponents";
import { useCallback, useContext, useEffect, useState } from "react";
import { customErrorInfo } from "../types/CustomErrorInfo";
import { responseCodeIsUnauth } from "../utils/responseCodes";
import { isNotEmptyObject, userLoaded } from "../utils/validation";

const isCustomErrorMessage = (data: any): data is customErrorInfo => {
	return (
		isNotEmptyObject(data) && "errorField" in data && "errorMessage" in data
	);
};

export const useFetch = (fetchUrl?: string) => {
	const { userId, handleUnauth } = useContext(AuthContext);
	const [data, setData] = useState({});
	const [errorInfo, setErrorInfo] = useState<customErrorInfo>();

	const [loadingStatus, setLoadingStatus] = useState(
		LoadingStatus.IsNotLoading
	);

	const handleResponse = useCallback(
		(fetchResponse: FetchResponse): void => {
			if (fetchResponse.ok) {
				const data: any = fetchResponse.body;
				setLoadingStatus(LoadingStatus.LoadedSuccessfully);
				setData(data);
			} else {
				if (responseCodeIsUnauth(fetchResponse)) {
					setLoadingStatus(LoadingStatus.EndedWithError);
					handleUnauth();
				} else {
					setLoadingStatus(LoadingStatus.EndedWithError);
				}
			}
		},
		[handleUnauth]
	);

	const handlePostError = async (fetchResponse: FetchResponse) => {
		let responseData;
		//json.parse needed as handleresponse in icas.core.reactcomponents currently bringing back text
		try {
			responseData = await JSON.parse(fetchResponse.body.toString()) ;
		} catch (e) {
			responseData = {};
		}
		if (isCustomErrorMessage(responseData)) {
			setErrorInfo(responseData);
		}
	};

	const handlePostResponse = useCallback(
		(fetchResponse: FetchResponse): LoadingStatus => {
			let saveStatus = LoadingStatus.EndedWithError;
			if (fetchResponse.ok) {
				return (saveStatus = LoadingStatus.LoadedSuccessfully);
			} else {
				if (responseCodeIsUnauth(fetchResponse)) {
					handleUnauth();
				} else {
					handlePostError(fetchResponse);
				}
			}
			return saveStatus;
		},
		[handleUnauth]
	);

	const getData = useCallback(async () => {
		if (fetchUrl) {
			setLoadingStatus(LoadingStatus.IsLoading);
			const fetchResponse = await FetchHelper.getJSON(
				fetchUrl.toString(),
				handleUnauth,
				true
			);
			handleResponse(fetchResponse);
		}
	}, [fetchUrl, handleResponse, handleUnauth]);

	const getDataFromOtherService = useCallback(
		async (
			otherServiceName: string,
			otherServiceUrl:string) => {
		if (otherServiceName && otherServiceUrl) {
			setLoadingStatus(LoadingStatus.IsLoading);
			const fetchResponse = await FetchHelper.getJSON(
				otherServiceUrl.toString(),
				handleUnauth,
				true,
				otherServiceName
			);
			handleResponse(fetchResponse);
		}
	}, [handleResponse, handleUnauth])

	useEffect(() => {
		if (userLoaded(userId, loadingStatus)) {
			getData();
		}
	}, [userId, getData, loadingStatus]);

	const postData = useCallback(
		async (
			data: {},
			postFetchUrl?: string
		): Promise<{ success: LoadingStatus; returnedData: any }> => {
			const url = postFetchUrl ?? fetchUrl ?? "";
			let returnedData;
			setErrorInfo(undefined);
			const fetchResponse = await FetchHelper.post(
				url.toString(),
				handleUnauth,
				data as unknown as BodyInit,
				true
			);
			const success: LoadingStatus = handlePostResponse(fetchResponse);
			if (success === LoadingStatus.LoadedSuccessfully) {
				returnedData = fetchResponse.body;
			}
			return { success, returnedData };
		},
		[fetchUrl, handlePostResponse, handleUnauth]
	);

	const putData = useCallback(
		async (
			data: any,
			postFetchUrl?: string
		): Promise<{ success: LoadingStatus; returnedData: any }> => {
			const url = postFetchUrl ?? fetchUrl ?? "";
			let returnedData;
			setErrorInfo(undefined);
			const fetchResponse = await FetchHelper.put(
				url.toString(),
				handleUnauth,
				data,
				true
			);
			const success: LoadingStatus = handlePostResponse(fetchResponse);
			if (success === LoadingStatus.LoadedSuccessfully) {
				returnedData = fetchResponse.body;
			}
			return { success, returnedData };
		},
		[fetchUrl, handlePostResponse, handleUnauth]
	);

	return { loadingStatus, data, errorInfo, handleResponse, postData, putData, getDataFromOtherService };
};
