import { MultiSelect, OptionSet } from "@icasdigital/icas.core.reactcomponents";
import React, { FC } from "react";

export type AdditionalServicesProps = {
	isWorking: boolean | undefined;
	additionalAccountingServices: OptionSet[] | undefined;
	handleMultiSelectChange: (
		id: string,
		checked: boolean,
		a: OptionSet[]
	) => void;
	additionalServicesOptions: OptionSet[];
};

export const AdditionalServices: FC<AdditionalServicesProps> = ({
	isWorking,
	additionalAccountingServices,
	handleMultiSelectChange,
	additionalServicesOptions,
}) => {
	const mappedOptions = additionalServicesOptions.map((option) => {
		return <MultiSelect.Option key={option.name} id={option.value.toString()} displayText={option.name}/>
	});

	const currentSelected = additionalAccountingServices?.map((s) => s.value.toString()) ?? [];

	const onClick = (id: string, checked: boolean) => {
		handleMultiSelectChange(id, checked, additionalServicesOptions);
	};


	return (
		<div className="section additional-services">
			<h3>
				<strong>
					Please tell us about your additional accounting services
				</strong>
			</h3>
			<p>
				Complete this section only if you are engaged in professional or
				business work, paid or unpaid, for someone
				{isWorking ? ` other than your employer.` : `.`}
			</p>
			<MultiSelect name={"additionalAccountingServices"} handleChange={onClick} heading="I perform this work in my capacity as:" checkedOptions={currentSelected}>
				<MultiSelect.Description description="(please select any that apply)"/>
				<MultiSelect.OptionContainer>
				{mappedOptions}
				</MultiSelect.OptionContainer>
			</MultiSelect>
		</div>
	);
};
