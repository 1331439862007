import { LoadingStatus } from "@icasdigital/icas.core.reactcomponents";
//#region type validation

export const isFunction = (
	possibleFunction: any
): possibleFunction is Function => typeof possibleFunction === "function";

export const isValidString = (possibleString: any): possibleString is string =>
	typeof possibleString === "string" && possibleString.length > 0;

export const isEmptyString = (possibleString: any): possibleString is string =>
	typeof possibleString === "string" && possibleString.length === 0;

export const isNumber = (possibleNumber: any): possibleNumber is number =>
	typeof possibleNumber === "number";

export const isNumberString = (possibleNumber: string): boolean => {
	const numberRegex = /^[0-9]*$/;
	return numberRegex.test(possibleNumber);
};

export const isUndefined = (
	possibleUndefined: any
): possibleUndefined is undefined => typeof possibleUndefined === "undefined";

export const isNotUndefined = (possibleUndefined: any): boolean =>
	typeof possibleUndefined !== "undefined";

export const isNotEmptyObject = (object: {}): boolean => {
	const hasKeys: boolean = Object.keys(object).length > 0;
	if (hasKeys) {
		const hasValues: boolean = Object.values(object).some(
			(value) => value !== null && isNotUndefined(value)
		);
		return hasKeys && hasValues;
	} else {
		return hasKeys;
	}
};

//#endregion

//#region rendering checks

export const pageLoaded = (currentLoading: LoadingStatus): boolean => {
	return (
		currentLoading === LoadingStatus.EndedWithError ||
		currentLoading === LoadingStatus.LoadedSuccessfully
	);
};

export const userLoaded = (
	userId: string | undefined,
	loadingStatus: LoadingStatus
): boolean => {
	return isValidString(userId) && loadingStatus === LoadingStatus.IsNotLoading;
};

export const pageAndUserLoaded = (
	userId: string | undefined,
	loadingStatus: LoadingStatus
): boolean => {
	return (
		loadingStatus !== LoadingStatus.IsLoading &&
		loadingStatus !== LoadingStatus.EndedWithError &&
		isValidString(userId)
	);
};

export const dataLoaded = (data: {}, loadingStatus: LoadingStatus): boolean => {
	return loadingStatus !== LoadingStatus.IsLoading && isNotEmptyObject(data);
};

export const arrayHasElements = (array: any[] | undefined): boolean => {
	return !isUndefined(array) && array.length > 0;
};
