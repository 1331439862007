export const formatAddressLine = ({
	homeAddressCity,
	homeAddressLine1,
}: {
	homeAddressCity?: string;
	homeAddressLine1?: string;
}): JSX.Element => {
	if (homeAddressCity && homeAddressLine1) {
		return (
			<span>
				We have you listed as living at <strong>{homeAddressLine1}</strong> in{" "}
				<strong>{homeAddressCity}</strong>
			</span>
		);
	}
	if (homeAddressLine1 || homeAddressCity) {
		const singleAddress = homeAddressCity ? <>in <strong>{homeAddressCity}</strong></> : <>at <strong>{homeAddressLine1}</strong></>;
		return (
			<span>
				We have you listed as living {singleAddress}
			</span>
		);
	}
	return <span>We do not have a complete address listed</span>;
};

export const  formatNonWorkLine = ({workingStatus}: {workingStatus?: string}) => {
		if (workingStatus) {
			return <span>
				{" "}and you are <strong>{workingStatus}</strong>
			</span>
		}	 
		return <span>
			. We do not have complete work details
		</span>		
	}

	export const formatWorkLine = ({ haveAddress, jobTitle, company, companyCity}: { haveAddress?: string, jobTitle?: string, company?: string,
	companyCity?: string; }): JSX.Element => {
			const addressString = haveAddress ? " and" : ". We have you listed as";
			const ifjobtitle = jobTitle ? "as" : "";

			if (company && companyCity) {
				return (
					<span>
						{addressString} working {ifjobtitle} <strong>{jobTitle}</strong> for <strong>{company}</strong> in{" "}
						<strong>{companyCity}</strong>
					</span>
				);
			} 
			if (company && !companyCity) {
				return (
					<span>
						{addressString} working {ifjobtitle} <strong>{jobTitle}</strong> for <strong>{company}</strong>
					</span>
				);
			} 
			return (
				<span>. We do not have complete work details</span>
				);
			
	};