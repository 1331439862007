import { Order } from "../types/Order";
import { CharityProduct, OrderProduct } from "../types/Product";

// Check whether order lines on given order differ from new order lines
export const getOrderLinesDiffer = (
	order: Order | undefined,
	newOrderLines: OrderProduct[]
): boolean => {
	if (order == null) {
		return true;
	} else if (
		order.orderLines.length !== newOrderLines.length
	) {
		return true;
	} else {
		// Find a new order line not in given order
		return (
			newOrderLines.findIndex(
				(newOrderLine) =>
					!order.orderLines.find(
						(l) =>
							l.cost === newOrderLine.cost && l.name === newOrderLine.name
					)
			) !== -1
		);
	}
};

// Get an order line for given charity donation
const getCharityOrderProduct = (
	products: CharityProduct[],
	charity: string,
	giftAid: boolean,
	amount?: number
): OrderProduct | null => {
	if (amount ?? 0 > 0) {
		// This assumes the product name contains all info we need
		const productsForCharity = products.filter((p) =>
			p.name.toLowerCase().includes(charity.toLowerCase())
		);
		const product = productsForCharity.find(
			(p) => p.name.toLowerCase().includes("gift aid") === giftAid
		);
		if (product) {
			return {
				productId: product.id,
				name: product.name,
				cost: amount,
			} as OrderProduct;
		}
	}
	return null;
};

type GetDonationsAsOrderLinesProps = {
	charityProducts: CharityProduct[];
	isGiftAidApplied: boolean;
	foundationAmount?: number;
	scabaAmount?: number;
};

// Get order lines from given charity state
export const getDonationsAsOrderLines = ({
	charityProducts,
	isGiftAidApplied,
	foundationAmount,
	scabaAmount
}: GetDonationsAsOrderLinesProps): OrderProduct[] => {
	let newOrderLines: OrderProduct[] = [];
	const orderProductICASFoundation = getCharityOrderProduct(
		charityProducts,
		"foundation",
		isGiftAidApplied,
		foundationAmount
	);
	if (orderProductICASFoundation) {
		newOrderLines.push(orderProductICASFoundation);
	}
	const orderProductSCABA = getCharityOrderProduct(
		charityProducts,
		"icas cares",
		isGiftAidApplied,
		scabaAmount
	);
	if (orderProductSCABA) {
		newOrderLines.push(orderProductSCABA);
	}
	return newOrderLines;
};

// Get order list updated with given new charity order lines 
export const getUpdatedOrders = (
	existingOrders: Order[],
	existingCharityOrder: Order | undefined,
	newCharityOrderLines: OrderProduct[]
): Order[] => {
	let charityOrder: Order = existingCharityOrder ?? {
		orderLines: [],
		outstandingAmount: 0,
		isCharityOrder: true,
	};
	charityOrder.orderLines = newCharityOrderLines.map((o) => {
		o.isActive = true;
		return o;
	});
	// Assumes that no payments were made to the previous charity order
	charityOrder.outstandingAmount = newCharityOrderLines
		.map((o) => o.cost)
		.reduce((sum, cost) => sum + cost, 0);

	// Replace the charity order in orders
	let newOrders = existingOrders.filter((o) => !o.isCharityOrder);
	newOrders.push(charityOrder);

	return newOrders;
};
