import { LoadingStatus } from "@icasdigital/icas.core.reactcomponents";

export type SaveInfo = {
	saveSent: boolean;
	saveState: LoadingStatus;
};

export const noSave: SaveInfo = {
	saveSent: false,
	saveState: LoadingStatus.IsNotLoading,
};
