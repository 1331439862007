import React, { createContext, useState, useEffect, FC } from "react";

export type ContextData = {
    isStarRatingSubmitted: boolean;
    isFeebackButtonDisabled: boolean;
	udpdateStarRatingSubmitted: (value : boolean) => void;
	udpdateFeedbackButtonDisabled: (value : boolean) => void;	
};

export const Context = createContext<ContextData>({
isStarRatingSubmitted: false,
udpdateStarRatingSubmitted: (value : boolean) => {},
isFeebackButtonDisabled: false,
udpdateFeedbackButtonDisabled: (value : boolean) => {}
});

type ContextProviderProps = {
    children: React.ReactElement;
}

export const ContextProvider = ({ children }: ContextProviderProps): JSX.Element => {
	const [starRatingSubmitted, setStarRatingSubmitted] = useState<boolean>(false);
    const [feedbackButtonDisabled, setFeedbackButtonDisabled] = useState<boolean>(false);

    const udpdateStarRatingSubmitted = (value : boolean) => {
        setStarRatingSubmitted(value);
    }	

    const udpdateFeedbackButtonDisabled = (value : boolean) => {
        setFeedbackButtonDisabled(value);
    }

	return (
		<Context.Provider
			value={{
                isStarRatingSubmitted: starRatingSubmitted, 
                udpdateStarRatingSubmitted: udpdateStarRatingSubmitted,
                isFeebackButtonDisabled:feedbackButtonDisabled,
                udpdateFeedbackButtonDisabled: udpdateFeedbackButtonDisabled
			}}
		>
			{children}
		</Context.Provider>
	);
};
