import { Button } from "@icasdigital/icas.core.reactcomponents";
import React, { FC, useContext } from "react";
import { Context } from "../../../context/ContextProvider";
import { useFeedbackComment } from "../../../hooks/useFeedbackComment";
import { SubmissionInfoBar } from "../../SubmissionInfoBar.tsx/SubmissionInfoBar";

export const FeedbackComment: FC = () => {
	const { submitFeedback, errorInfo, saveInfo, setComment, comment } = useFeedbackComment();
	const { isFeebackButtonDisabled } = useContext(Context);

	return (
		<>
			<label htmlFor="feedback-comment" id="feedback-label">
				We appreciate any feedback you have about your renewal experience.
			</label>
			<textarea
				id="feedback-comment"
				onChange={(e) => {
					setComment(e.currentTarget.value);
				}}
				value={comment}
				minLength={0}
				maxLength={150}
				placeholder="Add your comments here before submitting feedback"
			></textarea>
			<span id="feedback-length">{comment?.length ?? 0}/150</span>
			<Button id="feedback-button" style={{width: "9rem", margin: "auto"}} text="Send feedback" onClick={() => submitFeedback()} disabled={isFeebackButtonDisabled?true:false} />
			<SubmissionInfoBar
				invalidSubmit={false}
				errorInfo={errorInfo}
				saveInfo={saveInfo}
				successMessage="Thank you for your feedback. Redirecting you to ICAS.com"
			/>
		</>
	);
};
