import React, { FC } from "react";
import { FeedbackComment } from "./FeedbackComment";
import { StarRating } from "./StarRating";

export const FeedbackCard: FC = () => {
	return (
		<div className="card">
			<form
				name="Renewal Feedback"
				className="feedback card__content"
				onSubmit={(e) => {
					e.preventDefault();
				}}
			>
				<h2 className="welcome">Tell us how we did</h2>
				<StarRating />
				<FeedbackComment />
			</form>
		</div>
	);
};
