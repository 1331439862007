import { Checkbox } from "@icasdigital/icas.core.reactcomponents";
import React, { FC } from "react";
import "./card.css";

type ProfessionalDeclarationCardProps = {
	isProfessionalDeclarationSigned: boolean;
	handleChange: (id: string, checked: boolean) => void;
	invalidSubmit: boolean;
};

export const ProfessionalDeclarationCard: FC<
	ProfessionalDeclarationCardProps
> = ({ isProfessionalDeclarationSigned, handleChange, invalidSubmit }) => {
	return (
		<div className="card" id="professional-declaration-card">
			<div className="card__content professional-declaration">
				<h2 className="welcome with-line">Your professional declaration</h2>
			</div>
			<div>
				<p>
					I will continue to behave in a way that enhances and maintains the
					reputation of the profession, ICAS and its members around the globe.
					In addition, I have performed CPD relevant to my role or have been
					granted ongoing exemption from CPD by ICAS.
				</p>
			</div>
			<div className="i-agree-radiobutton">
				<Checkbox
					id="isProfessionalDeclarationSigned"
					label="I agree"
					handleChange={handleChange}
					checked={isProfessionalDeclarationSigned ?? false}
					invalidSubmit={invalidSubmit}
					required
					requiredMessage="Your professional declaration is required"
				/>
			</div>
		</div>
	);
};
