import { Button } from "@icasdigital/icas.core.reactcomponents";
import React, { FC, useEffect, useState } from "react";
import { AgreedPaymentMethodCode } from "../../../enums/agreedPaymentMethodCode";
import { Order } from "../../../types/Order";
import { NewDirectDebitOption } from "./NewDirectDebitOption";
import { OrderInfo } from "./OrderInfo";
import { PaymentInfo } from "./PaymentInfo";

const calculateOutstandingAmount = (orders: Order[]) => {
	let outstandingAmount = 0;
	if (orders.length) {
		orders.forEach((order) => (outstandingAmount += order.outstandingAmount));
	}
	return outstandingAmount;
};

const calculateCharityAmount = (orders: Order[]) => {
	let charityAmount = 0;
	if (orders.length) {
		orders.forEach(
			(order) =>
				(charityAmount += order.isCharityOrder ? order.outstandingAmount : 0)
		);
	}
	return charityAmount;
};

const existingDirectDebitPayment = (
	paymentCode?: AgreedPaymentMethodCode
): boolean => {
	if (paymentCode) {
		return [
			AgreedPaymentMethodCode.DD1,
			AgreedPaymentMethodCode.DD4,
			AgreedPaymentMethodCode.DD12,
		].includes(paymentCode);
	} else {
		return false;
	}
};

export const immediatePaymentNeeded = (
	outstandingAmount: number,
	paymentCode?: AgreedPaymentMethodCode,
	directDebitRequestedDate?: Date
): boolean => {
	return (
		!directDebitRequestedDate &&
		outstandingAmount > 0 &&
		!existingDirectDebitPayment(paymentCode) &&
		paymentCode !== AgreedPaymentMethodCode.PC
	);
};


const onlyCharityOrdersExisting = (orders: Order[]) => {
	let isOnlyCharityOrders = true;
	if (orders.length) {
		orders.forEach((order) => {
			if (!order.isCharityOrder) {
				isOnlyCharityOrders = false
			}
		}
		);
	}
	return isOnlyCharityOrders;
};

export type PaymentType =
	| "Firm"
	| "Immediate"
	| "DD"
	| "New DD"
	| "PC"
	| undefined;

type PaymentCardProps = {
	orders: Order[];
	directDebitRequestedDate?: Date;
	agreedPaymentMethodCode?: number;
	renewalYear: number;
	paymentResponsibility: "Firm" | "Personal";
	onSubmit: () => void;
	handleNewDirectDebitRequest: (id: string, value: boolean) => void;
};

export const PaymentCard: FC<PaymentCardProps> = ({
	orders,
	directDebitRequestedDate,
	agreedPaymentMethodCode: paymentMethod,
	renewalYear,
	paymentResponsibility,
	onSubmit,
	handleNewDirectDebitRequest,
}) => {
	const [paymentType, setPaymentType] = useState<PaymentType>(undefined);

	const outstandingAmount = calculateOutstandingAmount(orders);
	const charityAmount = calculateCharityAmount(orders);
	const isCharityOnly = onlyCharityOrdersExisting(orders);

	useEffect(() => {
		if (outstandingAmount) {
			if (paymentResponsibility === "Firm") {
				setPaymentType("Firm");
			} else {
				if (
					immediatePaymentNeeded(
						outstandingAmount,
						paymentMethod,
						directDebitRequestedDate
					)
				) {
					setPaymentType("Immediate");
				} else if (directDebitRequestedDate) {
					setPaymentType("New DD");
				} else if (existingDirectDebitPayment(paymentMethod)) {
					setPaymentType("DD");
				} else if (paymentMethod === AgreedPaymentMethodCode.PC) {
					setPaymentType("PC");
				}
			}
		}
	}, [
		paymentResponsibility,
		directDebitRequestedDate,
		paymentMethod,
		outstandingAmount,
	]);

	return (
		<div className="card payment">
			<div className="card__content">
				<h2 className="welcome with-line">Payment</h2>
				<OrderInfo
					orders={orders}
					renewalYear={renewalYear}
					totalBalanceDue={
						paymentType?.includes("DD") || paymentType === "Firm"
							? charityAmount
							: outstandingAmount
					}
					paymentResponsibility={paymentResponsibility}
				/>
				<NewDirectDebitOption
					isCharityOnly={isCharityOnly}
					paymentType={paymentType}
					handleNewRequest={handleNewDirectDebitRequest}
					directDebitRequestedDate={directDebitRequestedDate}
				/>
				<PaymentInfo paymentType={paymentType} />
				<Button
					id="renew-button"
					text="Renew"
					style={{ margin: "auto", width: "20em"}}
					onClick={onSubmit}
				/>
			</div>
		</div>
	);
};
