import React, { FC, useContext } from "react";
import { AuthContext, LoadingStatus, PrivateRoute, Error, LoadingLottie } from "@icasdigital/icas.core.reactcomponents";
import { Navigate, Route, Routes } from "react-router-dom";
import { useJourney } from "../hooks/useJourney";
import { useLogin } from "../hooks/useLogin";
import { CompleteContainer } from "./CompleteContainer";
import { RenewalContainer } from "./RenewalContainer";

export const JourneyContainer = () => {
	const { userId } = useContext(AuthContext);
	const { defaultRoute, loadingStatus } = useJourney(userId);
	const { loginPath } = useLogin();

	if (defaultRoute && loadingStatus === LoadingStatus.LoadedSuccessfully) {
		return (
			<Routes>
				<Route  path="/" element={<Navigate to={defaultRoute} />} />
				<Route element={<PrivateRoute loginPath={loginPath} limitedRoles={["Members"]} />}>
				<Route
					path="/form"
					element={<RenewalContainer />}
				/>
				<Route
				path="/complete"
					element={<CompleteContainer />}
				/>
				</Route>
			</Routes>
		);
	} else if(loadingStatus === LoadingStatus.EndedWithError) {
		return <Error />;
	} else if (loadingStatus === LoadingStatus.IsLoading) {
		return <LoadingLottie style={{ height: "20em", margin: "auto" }} />;
	} else {
		return <></>;
	}
}