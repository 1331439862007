import React, { FC } from "react";
import {  Radio } from "@icasdigital/icas.core.reactcomponents";
import { useBooleanRadioButtons } from "../../../hooks/useBooleanRadioButtons";

const radioHeading = "ICAS Foundation would like to keep in touch with you to tell you about their work, and the difference your support makes. Do you consent to ICAS Foundation contacting you by email for this purpose?"

type UpdatesFromICASFoundationProps = {
	consentGiven?: boolean;
	handleChange: (stateName: string, consentGiven: boolean) => void;
};

export const UpdatesFromICASFoundation: FC<UpdatesFromICASFoundationProps> = ({
	consentGiven,
	handleChange,
}) => {
	const { selectedValue, yesValue, noValue, findValue } = useBooleanRadioButtons(consentGiven);

	const handleChangeOfConsent = (name: string, value: string | number) => {
		const key = findValue(name, value);
		if (key === undefined) {
			return;
		}
		handleChange(name, key);
	};

	return (
		<>
			<Radio heading={radioHeading} name="isFoundationContactConsentGiven" handleChange={handleChangeOfConsent} selectedValue={selectedValue}>
				<Radio.ButtonContainer vertical style={{gap: "8px"}}>
					<Radio.Button labelText="Yes" id="foundation-consent__true" value={yesValue} />
					<Radio.Button labelText="No" id="foundation-consent__false" value={noValue} />
				</Radio.ButtonContainer>
			</Radio>
			<p>
				<i>ICAS Foundation is fully committed to handling personal information in accordance with data protection legislation and best data protection practices. See <a href="https://www.icasfoundation.org.uk/__data/assets/pdf_file/0004/570451/ICASF-privacy-policy-2018.pdf">ICAS Foundation Privacy Policy</a></i>
			</p>
		</>
	);
};
