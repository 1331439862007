import { getKeyByValue } from "../utils/Formatting";

const yesValue = "yes";
const noValue = "no";

const BooleanRadioButtonValues = {
	[yesValue]: true,
	[noValue]: false,
};

const findCurrentValue = (currentValue?: boolean) => {
	if (currentValue === undefined) {
		return;
	}
	return getKeyByValue({
		object: BooleanRadioButtonValues,
		value: currentValue,
	});
};

	const findValue = (name: string, value: string | number) => {
		if (typeof value === "number") {
			return;
		}
		return BooleanRadioButtonValues[value as keyof typeof BooleanRadioButtonValues];
	};

export const useBooleanRadioButtons = (currentValue?: boolean) => {
    const selectedValue = findCurrentValue(currentValue);

    return { selectedValue, yesValue, noValue, findValue }

}
