import { Donation, DonationInfo } from "./Donation";
import { UpdatesFromICASFoundation } from "./UpdatesFromICASFoundation";
import { GiftAid } from "./GiftAid";

export type CharityState = {
	isFoundationContactConsentGiven?: boolean;
	isGiftAidApplied: boolean;
	donationICASFoundation?: DonationInfo;
	donationSCABA?: DonationInfo;
};

type CharityCardProps = {
	charityState: CharityState;
	handleDonationChange: (stateName: string, donation: DonationInfo) => void;
	handleCheckboxChange: (stateName: string, checked: boolean) => void;
};

	const foundationDonationOptions = [
		{ id: 1, amount: 100, labelText: `${100}`, description: "Could help a student to buy their first formal business outfit for a summer internship." },
		{ id: 2, amount: 50, labelText: `${50}`, description: "Could allow a student to choose study over paid work the night before an exam." },
		{ id: 3, amount: 30, labelText: `${30}`, description: "Could buy a headset to help a student focus when there isn't a quiet place to study at home" },
		]

	const scabaDonationOptions = [
		{ id: 1, amount: 100,labelText: `${100}`},
		{ id: 2, amount: 50, labelText: `${50}` },
		{ id: 3, amount: 30, labelText: `${30}` },
		{ id: 4, amount: 0, labelText: `I already donate`, },
		{ id: 5, amount: 0, labelText: `I do not wish to donate`, },
	]
			

export const CharityCard = ({
	charityState,
	handleDonationChange,
	handleCheckboxChange,
}: CharityCardProps) => {
	const haveDonation =
		(charityState.donationICASFoundation?.amount ?? 0) +
			(charityState.donationSCABA?.amount ?? 0) >
		0;

	return (
		<div className="card charity">
			<div className="card__content">
				<h2 className="welcome with-line">Add a charity donation</h2>
				<Donation
					name="donationICASFoundation"
					title="ICAS Foundation (SC034836)"
					subtitle="ICAS Foundation is a charity that transforms the lives of talented young people from low-income backgrounds by enabling them to study finance and accounting."
					description={
						<>
							<p>Our students have often experienced significant challenges in life, but when given a chance on the programme their talent and determination show in their achievements: 89% of our graduates obtain first or upper second-class honours, and 68% progress to CA training.</p>
							<p>Generous CAs like you make this possible, and we need your support to go further. By 2030 we aim to triple the number of students on the programme, but this will only be possible with significant support from within the profession.</p>
							<p>Your gift today can support our students to flourish - and our programme to grow.</p>
						</>
					}
						website={{
						url: "https://icasfoundation.org.uk/",
						title: "ICAS Foundation website",
					}}
					donationOptions={foundationDonationOptions}
					donation={charityState.donationICASFoundation}
					handleChange={handleDonationChange}
				/>
				<UpdatesFromICASFoundation
					consentGiven={charityState.isFoundationContactConsentGiven}
					handleChange={handleCheckboxChange}
				/>
				<hr />
				<Donation
					name="donationSCABA"
					title="ICAS Cares (SC052865)"
					subtitle=""
					description={
						<>
							ICAS Cares is the charity dedicated to supporting past and present ICAS members, their dependents and future CAs facing hard times. The organisation continues the help previously provided for over 100 years by the Scottish Chartered Accountant's Benevolent Association (SCABA).
						</>
					}
				website={{
						url: "https://www.icas.com/icas-cares",
						title: "ICAS Cares website",
					}}
					donationOptions={scabaDonationOptions}
					donation={charityState.donationSCABA}
					handleChange={handleDonationChange}
				/>
				<hr />
				{haveDonation && (
					<GiftAid
						isApplied={charityState.isGiftAidApplied}
						handleChange={handleCheckboxChange}
					/>
				)}
			</div>
		</div>
	);
};
