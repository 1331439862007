import {  useContext } from "react";
import {
	AuthContext,
	Error,
	LoadingLottie,
	LoadingStatus,
} from "@icasdigital/icas.core.reactcomponents";
import { useFetch } from "../hooks/useFetch";
import { GreetingCard } from "../components/cards/Greeting/GreetingCard";
import { ProfessionalDeclarationCard } from "../components/cards/ProfessionalDeclarationCard";
import "./container-style.css";
import { RenewalData } from "../types/RenewalData";
import { useUpdateData } from "../hooks/useUpdateData";
import { dataLoaded } from "../utils/validation";
import { PaymentCard } from "../components/cards/Payment/PaymentCard";
import { CharityCard } from "../components/cards/Charity/CharityCard";
import { RegulationCard } from "../components/cards/Regulation/RegulationCard";
import { LookupData } from "../types/LookupData";
import { SubmissionInfoBar } from "../components/SubmissionInfoBar.tsx/SubmissionInfoBar";



export const RenewalContainer = () => {
	//#region State and Context Management

	const { userId, userName } = useContext(AuthContext);
	const {
		loadingStatus,
		data: fetchedData,
		errorInfo,
	} = useFetch(`/renewal/${userId}`);
	const { loadingStatus: dataLoadingStatus, data: lookups } =
		useFetch("/lookup/options");

	const {
		data,
		handleNewDirectDebitRequest,
		handleMultiSelectChange,
		charityState,
		handleChangeToPractisingCertificateStatus,
		handleCheckBoxChange,
		handleRenewalSubmit,
		handleDonationChange,
		handleCharityCheckboxChange,
		saveInfo,
		invalidSubmission,
	} = useUpdateData(fetchedData as RenewalData);

	const lookupData = lookups as LookupData;

	const isFullyLoaded =	dataLoaded(data, loadingStatus) &&
		dataLoaded(lookups, dataLoadingStatus)

	//#endregion

	if (isFullyLoaded) {
		return (
			<div className="app-container">
				<GreetingCard
					renewalData={data as RenewalData}
					name={userName}
					profileLink={data.profileApp}
				/>
				<ProfessionalDeclarationCard
					isProfessionalDeclarationSigned={
						data.isProfessionalDeclarationSigned ?? false
					}
					handleChange={handleCheckBoxChange}
					invalidSubmit={invalidSubmission}
				/>
				<RegulationCard
					practisingCertificate={{
						renewalYear: data.renewalYear,
						isPractisingCertificateHolder: data.isPractisingCertificateHolder,
						practisingCertificateStatus: data.practisingCertificateStatus,
						handleChange: handleChangeToPractisingCertificateStatus,
						invalidSubmit: invalidSubmission,
					}}
					additionalServices={{
						isWorking: data.isWorking,
						additionalAccountingServices: data.additionalAccountingServices,
						additionalServicesOptions: lookupData.icas_occasionalworkoptions,
						handleMultiSelectChange: handleMultiSelectChange,
					}}
				/>
				<CharityCard
					charityState={charityState}
					handleDonationChange={handleDonationChange}
					handleCheckboxChange={handleCharityCheckboxChange}
				/>

				<PaymentCard
					orders={data.orders}
					directDebitRequestedDate={data.directDebitRequestedDate}
					agreedPaymentMethodCode={data.agreedPaymentMethodCode}
					renewalYear={data.renewalYear}
					paymentResponsibility={data.paymentResponsibility ?? "Personal"}
					onSubmit={handleRenewalSubmit}
					handleNewDirectDebitRequest={handleNewDirectDebitRequest}
				/>
				<SubmissionInfoBar
					invalidSubmit={invalidSubmission}
					saveInfo={saveInfo}
					errorInfo={errorInfo}
					successMessage="Thank you for your submission"
				/>
			</div>
		);
	} 
	if (loadingStatus === LoadingStatus.EndedWithError) {
		return <Error />
	} 
	if (loadingStatus === LoadingStatus.IsLoading) {
		return <LoadingLottie  />;
	}
	return <></>;
};
