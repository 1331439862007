import React, { FC } from "react";
import {
	PractisingCertificate,
	PractisingCertificateProps,
} from "./PractisingCertificate";
import "../card.css";
import {
	AdditionalServices,
	AdditionalServicesProps,
} from "./AdditionalServices";

export type RegulationCardProps = {
	practisingCertificate: PractisingCertificateProps;
	additionalServices: AdditionalServicesProps;
};

export const RegulationCard: FC<RegulationCardProps> = ({
	practisingCertificate,
	additionalServices,
}) => {
	return (
		<div className="card regulation" id="regulation-card">
			<div className="card__content">
				<h2 className="welcome with-line">Regulation</h2>
				<PractisingCertificate
					renewalYear={practisingCertificate.renewalYear}
					isPractisingCertificateHolder={
						practisingCertificate.isPractisingCertificateHolder
					}
					practisingCertificateStatus={
						practisingCertificate.practisingCertificateStatus
					}
					handleChange={practisingCertificate.handleChange}
					invalidSubmit={practisingCertificate.invalidSubmit}
				/>
				<hr className="separator" />
				<AdditionalServices
					isWorking={additionalServices.isWorking}
					additionalAccountingServices={
						additionalServices.additionalAccountingServices
					}
					handleMultiSelectChange={additionalServices.handleMultiSelectChange}
					additionalServicesOptions={
						additionalServices.additionalServicesOptions
					}
				/>
			</div>
		</div>
	);
};
