import { Link } from "react-router-dom";
import "./card.css";

const contactUsEmailAddress ="members@icas.com";

export const ThankYou= () => {
	return (
		<div className="card thank-you">
			<div className="card__content">
				<div className="detail">
					<h2 className="welcome with-line">Thank you</h2>
					<h3><strong>Your renewal has been received</strong></h3>
					<p>Any questions? <Link to={`mailto:${contactUsEmailAddress}`}>Get in touch with us</Link></p>
				</div>
			</div>
		</div>
	);
};
