import React, { FC, useEffect, useState } from "react";
import { Order } from "../../../types/Order";
import { OrderProduct } from "../../../types/Product";
import { renderGBP } from "../../../utils/Formatting";

const calculateTotal = (products: OrderProduct[]) => {
	let totalCost = 0;
	products.forEach((product) => (totalCost += product.cost));
	return totalCost;
};

type OrderInfoProps = {
	orders: Order[];
	renewalYear: number;
	totalBalanceDue: number;
	paymentResponsibility:string
};

export const OrderInfo: FC<OrderInfoProps> = ({ orders, renewalYear, totalBalanceDue, paymentResponsibility }) => {
	const [orderLines, setOrderLines] = useState<OrderProduct[]>();

	useEffect(() => {
		let lines: OrderProduct[] = [];
		orders.forEach((order) => {
			lines = [...lines, ...order.orderLines];
		});
		setOrderLines(lines);
	}, [orders]);

	if (orderLines && paymentResponsibility === "Personal") {
		const activeOrderLines = orderLines?.filter(l => l.isActive); 
		const costLines = activeOrderLines.map((product) => {
			return (
				<span key={product.name} className="costLine">
					<p>{product.name}</p>
					<p>{renderGBP(product.cost)}</p>
				</span>
			);
		});

		const total = calculateTotal(activeOrderLines);

		return (
			<>
				<p>
					For more information on subscription rate criteria visit our{" "}
					<a
						href="https://dev.icas.com/members/manage-my-membership/subscription/membership-subscription-categories"
						target="_blank"
						rel="noreferrer"
					>
						subscription rate page.
					</a>
				</p>
				<hr />
				{costLines}
				<hr />
				<span className="costLine">
					<p>Total*</p>
					<p>{renderGBP(total)}</p>
				</span>
				<hr />
				<span className="costLine">
					<p>Total balance due</p>
					<p>{renderGBP(totalBalanceDue)}</p>
				</span>
				<hr />
				<p>
					<b>
						In addition to your annual subscription, members are charged a levy
						towards the case costs paid by ICAS to the FRC under the Public
						Disciplinary Scheme. The value of the levy is reviewed by Council
						each year. For {renewalYear}, the value of this levy is £0.
					</b>
				</p>
			</>
		);
	} else {
		return <></>;
	}
};
