import { AuthContext, LoadingStatus } from "@icasdigital/icas.core.reactcomponents";
import React, { useContext, useState} from "react";
import { Context } from "./../context/ContextProvider";
import { useFetch } from "./useFetch";
import { noSave, SaveInfo } from "../types/SaveInfo";

export const useFeedbackComment = () => {
	const [comment, setComment] = useState<string | undefined>("");
	const [saveInfo, setSaveInfo] = useState<SaveInfo>(noSave);
	const { isStarRatingSubmitted, udpdateFeedbackButtonDisabled } = useContext(Context);
	const { userId } = useContext(AuthContext);
	const { putData, errorInfo } = useFetch();

	const submitFeedback = () => {		
		const submitForm = async () => {
			if (comment) {
				setSaveInfo({
					saveSent: true,
					saveState: LoadingStatus.IsLoading,
				});
				const { success: saveStatus } = await putData(
					comment,
					`/renewal/${userId}/feedback/text`
				);
				setSaveInfo({
					saveSent: true,
					saveState: saveStatus,
				});
				if (saveStatus === LoadingStatus.LoadedSuccessfully) {
					setTimeout(redirectToHomepage, 1000);
				}
			}
		}
		if (isStarRatingSubmitted) {
			udpdateFeedbackButtonDisabled(true);
			setTimeout(redirectToHomepage, 1000);
			submitForm();			
		}
	};

	const redirectToHomepage = () => {
		//TODO: replace with variable
		return (window.location.href = "https://www.icas.com");
	};

	return { submitFeedback, saveInfo, errorInfo, setComment, comment };
};