import React, { FC} from "react";
import { PaymentType } from "./PaymentCard";

const existingDirectDebitLine = (
	<p>
		*Your payment of fees is already scheduled to be{" "}
		<strong>paid by Direct Debit.</strong>
	</p>
);

const firmPayingLine = (
	<p>
		*Your payment of fees is already scheduled to be{" "}
		<strong>paid by your employer.</strong>
	</p>
);

const pcPayingLine = (
	<p>
		*Your payment of fees is already scheduled to be{" "}
		<strong>paid by installment.</strong>
	</p>
);

const personalCreditCardPayingLine = (
	<p>
		When you click the 'Renew' button, you will be forwarded to our secure
		payment page for card payment. Your Renewal is not finalised until we are in
		receipt of payment.
	</p>
);

const newDirectDebitLine = (
	<p>
		{" "}
		When you click the 'Renew' button, you will be forwarded to our Direct Debit
		page. Your Renewal is not finalised until the Direct Debit has been set up.
		Please note that only the renewal fees are taken by Direct Debit and any
		charity payments will need to be paid for afterwards.{" "}
	</p>
);

type PaymentInfoProps = {
	paymentType: PaymentType;
};

export const PaymentInfo: FC<PaymentInfoProps> = ({ paymentType }) => {
	switch (paymentType) {
		case "Firm":
			return firmPayingLine;
		case "Immediate":
			return personalCreditCardPayingLine;
		case "DD":
			return existingDirectDebitLine;
		case "New DD":
			return newDirectDebitLine;
		case "PC":
			return pcPayingLine;
		default:
			return <></>;
	}
};
