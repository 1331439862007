import React, { FC } from "react";
import { Radio } from "@icasdigital/icas.core.reactcomponents";
import { Link } from "react-router-dom";
import { isUndefined } from "../../../utils/validation";
import { useBooleanRadioButtons } from "../../../hooks/useBooleanRadioButtons";

export type PractisingCertificateApplicationProps = {
	statementsApply?: boolean;
	handleChange: (statementsApply: boolean) => void;
	invalidSubmit: boolean;
};

export const PractisingCertificateApplication: FC<
	PractisingCertificateApplicationProps
> = ({ statementsApply, handleChange, invalidSubmit }) => {
	const { selectedValue, yesValue, noValue, findValue } =
		useBooleanRadioButtons(statementsApply);

	const showError = isUndefined(statementsApply) && invalidSubmit;

	const handleChangeOfStatementsApply = (
		id: string,
		value: string | number
	) => {
		const newValue = findValue(id, value);
		if (newValue === undefined|| !handleChange) {
			return;
		}
		handleChange(newValue);
	};

	return (
		<>
			<h4>Do I need a practising certificate?</h4>
			<p>
				According to our public practice regulations you will be engaging in
				practice if you are:
			</p>
			<ul>
				<li>
					providing accounting and related services, to persons other than your
					employer, for a fee; or
				</li>
				<li>
					a{" "}
					<Link to="https://icas.com/regulation/practising-certificates#principal">
						Principal
					</Link>{" "}
					in a FIRM providing accountancy or related services.
				</li>
			</ul>
			<p>
				Insolvency Practitioners (IP) are required to hold a practising
				certificate in order to be:
			</p>
			<ul>
				<li>eligible to accept IP appointments as an IP in the UK;</li>
				<li>
					eligible to accept personal insolvency appointments in the Republic of
					Ireland; and/or
				</li>
				<li>
					eligible to accept corporate insolvency appointments in the Republic
					of Ireland
				</li>
			</ul>
			<Radio
				name="statementsApply"
				heading={"Do any of these statements apply?"}
				selectedValue={selectedValue}
				handleChange={handleChangeOfStatementsApply}
			>
				<Radio.ButtonContainer style={{ flexDirection: "column", gap: "8px", width: "40%" }}>
					<Radio.Button
						id="yes-statementsApply"
						labelText="Yes"
						value={yesValue}
					/>
					<Radio.Button
						id="no-statementsApply"
						labelText="No"
						value={noValue}
					/>
				</Radio.ButtonContainer>
			</Radio>
			{showError && (
				<div className="invalidMessage" style={{ textAlign: "center" }}>
					Please select an answer
				</div>
			)}
			<div className="action">
				{statementsApply === true && (
					<>
						<h4>
							It looks like you may need to apply for a practising certificate.
						</h4>
						<p>
							Our regulation team will be in contact with more information soon.
						</p>
					</>
				)}
				{statementsApply === false && (
					<>
						<h4>
							It looks like you do not need to apply for a practising
							certificate.
						</h4>
					</>
				)}
			</div>
		</>
	);
};
