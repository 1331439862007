import { useContext, useEffect, useState } from "react";
import { AuthContext, LoadingStatus } from "@icasdigital/icas.core.reactcomponents";
import { useFetch } from "./useFetch";
import { JourneyData } from "../types/JourneyData";
import { usePaymentService } from "./usePaymentService";

const isJourneyData = (data: {}): data is JourneyData => {
	return "isComplete" in data && "redirectDestination" in data;
};

export type PossibleRoute = "/form" | "/complete" | null;

export const useJourney = (userId: string) => {
	const [defaultRoute, setDefaultRoute] = useState<PossibleRoute>(null);
	const [journeyData, setJourneyData] = useState<JourneyData>();
	const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.IsNotLoading);
	const { data: journeyFetchData, loadingStatus:journeyLoadingStatus } = useFetch(
		`/renewal/${userId}/journey`
	);
	const {
		loadingStatus:paymentLoadingStatus, 
		paymentIsRequired,
		getPaymentRequired
	} = usePaymentService();
	
	useEffect(() => {
		if (userId !== null && isJourneyData(journeyFetchData)) {
			setJourneyData(journeyFetchData);
		}
	}, [journeyFetchData, userId]);

	useEffect(() => {
		if (journeyData && (paymentLoadingStatus === LoadingStatus.IsNotLoading || paymentLoadingStatus === LoadingStatus.LoadedSuccessfully)) {
			if (journeyData.redirectDestination) {
				if (journeyData.checkPaymentStatusUrl) {
					getPaymentRequired(journeyData.checkPaymentStatusUrl);
				} else {
					return window.location.replace(journeyData.redirectDestination);
				}
			} else {
				let newRoute:PossibleRoute = journeyData.isComplete
					? "/complete"
					: "/form";
				if (window.location.href.endsWith(newRoute)) {
					setDefaultRoute(newRoute);
				} else {
					window.location.replace(newRoute);
				}
			}
		}
	}, [journeyData, getPaymentRequired, paymentLoadingStatus]);

	useEffect(() => {
		if (journeyData && journeyData.redirectDestination) {
			if (paymentIsRequired === true) {
				return window.location.replace(journeyData.redirectDestination);
			} else if (paymentIsRequired === false) {
				setJourneyData({
					...journeyData,
					redirectDestination: undefined,
					checkPaymentStatusUrl: undefined
				});
			}
		}
	}, [paymentIsRequired, journeyData]);

	// Combine loading status for journey and payment status
	useEffect(() => {
		if (journeyLoadingStatus === LoadingStatus.IsLoading || paymentLoadingStatus === LoadingStatus.IsLoading) {
			setLoadingStatus(LoadingStatus.IsLoading);
		} else if (journeyLoadingStatus === LoadingStatus.EndedWithError || paymentLoadingStatus === LoadingStatus.EndedWithError) {
			setLoadingStatus(LoadingStatus.EndedWithError);
		} else if (journeyData && (!journeyData?.checkPaymentStatusUrl || paymentLoadingStatus === LoadingStatus.LoadedSuccessfully)) {
			setLoadingStatus(LoadingStatus.LoadedSuccessfully);
		} 
	}, [journeyLoadingStatus, journeyData, paymentLoadingStatus, setLoadingStatus])

	return { defaultRoute, loadingStatus };
}
