import React, { FC } from "react";
import { PractisingCertificateChange } from "../../../types/RenewalData";
import { PractisingCertificateApplication } from "./PractisingCertificateApplication";
import { PractisingCertificateHolder } from "./PractisingCertificateHolder";

export type PractisingCertificateProps = {
	renewalYear: number;
	isPractisingCertificateHolder: boolean;
	practisingCertificateStatus: PractisingCertificateChange
	handleChange: (practisingCertificateStatus: PractisingCertificateChange) => void;
	invalidSubmit: boolean
};

export const PractisingCertificate: FC<PractisingCertificateProps> = ({
	renewalYear,
	isPractisingCertificateHolder,
	practisingCertificateStatus,
	handleChange,
	invalidSubmit
}) => {
	return (
		<>
			{isPractisingCertificateHolder && 
				<PractisingCertificateHolder
					renewalYear={renewalYear}
					cancellationRequested={practisingCertificateStatus === null ? undefined : practisingCertificateStatus === "Cancel"}
					handleChange={(cancel: boolean) => handleChange(cancel ? "Cancel" : "NA")}
				/>}
			{!isPractisingCertificateHolder && 
				<PractisingCertificateApplication
					statementsApply={practisingCertificateStatus === null ? undefined : practisingCertificateStatus === "Apply"}
					handleChange={(apply: boolean) => handleChange(apply ? "Apply" : "NA")}
					invalidSubmit={invalidSubmit}
			/>}
		</>);
}
