import { Radio} from "@icasdigital/icas.core.reactcomponents";
import { FC } from "react";
import { PaymentType } from "./PaymentCard";
import { useBooleanRadioButtons } from "../../../hooks/useBooleanRadioButtons";

type NewDirectDebitOptionProps = {
	isCharityOnly: boolean;
	paymentType: PaymentType;
	directDebitRequestedDate?: Date;
	handleNewRequest: (id: string, value: boolean) => void;
};

export const NewDirectDebitOption: FC<NewDirectDebitOptionProps> = ({
	isCharityOnly,
	paymentType,
	directDebitRequestedDate,
	handleNewRequest,
}) => {
	const isDDRequested = Boolean(directDebitRequestedDate);
	const { selectedValue, yesValue, noValue, findValue } = useBooleanRadioButtons(isDDRequested);

	const isDDEligible = (paymentType === "Immediate" || paymentType === "New DD") && !isCharityOnly;

	const handleClick = (name: string, value: string | number) => {
		const key = findValue(name, value);
		if (key === undefined) {
			return;
		}
		handleNewRequest(name, key);
	};

	if (isDDEligible) {
		return (
			<Radio heading={"Would you like to set up a Direct Debit?"}
				name="newDirectDebit"
				handleChange={handleClick}
				selectedValue={selectedValue}
			>
				<Radio.ButtonContainer>
					<Radio.Button labelText="Yes" id="dd__true" value={yesValue} />
					<Radio.Button labelText="No" id="dd__false" value={noValue} />
				</Radio.ButtonContainer>

			</Radio>
		);
	} 
	return <></>;
};
