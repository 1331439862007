import React, { useEffect, useState } from "react";
import { useFetch } from "./useFetch";
import { OrderPaymentStatus } from "../types/OrderPaymentStatus";

const isOrderPaymentStatus = (data: {}): data is OrderPaymentStatus => {
	return "isFulfilled" in data && "paymentIsProcessing" in data;
};

export const usePaymentService = () => {
	const [paymentIsRequired, setPaymentIsRequired] = useState<boolean>();
	const { 
		getDataFromOtherService,
		data: paymentStatus,
		loadingStatus
	} = useFetch();

	const getPaymentRequired = (checkPaymentUrl:string) => {
		getDataFromOtherService("Payment", checkPaymentUrl);
	}

	useEffect(() => {
		if (paymentStatus && isOrderPaymentStatus(paymentStatus)) {
			setPaymentIsRequired(!(paymentStatus.isFulfilled || paymentStatus.paymentIsProcessing));
		}
	}, [paymentStatus])

	return { loadingStatus, paymentIsRequired, getPaymentRequired }
};
