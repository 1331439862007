import {
	AuthContext,
	LoadingStatus,
	OptionSet,
} from "@icasdigital/icas.core.reactcomponents";
import { useContext, useEffect, useState } from "react";
import { CharityState } from "../components/cards/Charity/CharityCard";
import { DonationInfo } from "../components/cards/Charity/Donation";
import { PractisingCertificateChange, RenewalData } from "../types/RenewalData";
import { RenewalSubmission } from "../types/RenewalSubmission";
import { noSave, SaveInfo } from "../types/SaveInfo";
import { useFetch } from "./useFetch";
import {
	getDonationsAsOrderLines,
	getOrderLinesDiffer,
	getUpdatedOrders,
} from "../utils/charityOrder";

const findOptionSet = (
	value: number,
	optionSetArray: OptionSet[]
): OptionSet | undefined => {
	return optionSetArray.find((option) => option.value === value);
};

const scrollToField = (id: string) => {
	const element = document.getElementById(id);
	if (element) {
		element.scrollIntoView({ behavior: "smooth" });
	}
};

const formatIdForStateName = (id: string): string => {
	if (id.includes("_")) {
		return id.split("_")[0];
	}
	return id;
};

const isInvalidField = (
	PDSigned: boolean | undefined,
	PCHolder: boolean,
	PCStatus: PractisingCertificateChange | undefined
): string | false => {
	//members must agree to PD and review pc status if they do not hold one
	if (!PDSigned) {
		return "professional-declaration-card";
	} else if (!PCHolder && !PCStatus) {
		return "regulation-card";
	} else {
		return false;
	}
};

const formatSubmission = (
	data: RenewalData,
	charityState: CharityState
): RenewalSubmission => {
	const occasionalWorkOptionsNumbers =
		data.additionalAccountingServices?.map((service) => service.value) ?? [];
	if (data.practisingCertificateStatus) {
		return {
			membershipRecordId: data.membershipRecordId,
			isProfessionalDeclarationSigned: data.isProfessionalDeclarationSigned,
			isFoundationContactConsentGiven:
				charityState.isFoundationContactConsentGiven,
			practisingCertificateStatus: data.practisingCertificateStatus,
			orders: data.orders,
			occasionalWorkOptions: occasionalWorkOptionsNumbers,
			directDebitRequestedDate: data.directDebitRequestedDate,
		};
		//if a member currently has a PC they do not need to confirm status so nothing to send to back end
	} else {
		return {
			membershipRecordId: data.membershipRecordId,
			isProfessionalDeclarationSigned: data.isProfessionalDeclarationSigned,
			isFoundationContactConsentGiven:
				charityState.isFoundationContactConsentGiven,
			orders: data.orders,
			occasionalWorkOptions: occasionalWorkOptionsNumbers,
			directDebitRequestedDate: data.directDebitRequestedDate,
		};
	}
};

const initialCharityState = {
		// TODO: this can be separate to avoid recalc of donations
		isFoundationContactConsentGiven: false,
		isGiftAidApplied: false,
	}

export const useUpdateData = (fetchedData: RenewalData) => {
	const [data, setData] = useState<RenewalData>({} as RenewalData);
	const [charityState, setCharityState] = useState<CharityState>(initialCharityState);
	const [isDirty, setIsDirty] = useState(false);
	const [saveInfo, setSaveInfo] = useState<SaveInfo>(noSave);
	const [invalidSubmission, setInvalidSubmission] = useState(false);
	const { userId } = useContext(AuthContext);
	const { postData, errorInfo } = useFetch(`/renewal/${userId}`);

	useEffect(() => {
		setData(fetchedData);
		setCharityState((prevCharityState) => {
			return {
				...prevCharityState,
				isFoundationContactConsentGiven:
					fetchedData.isFoundationContactConsentGiven,
			};
		});
	}, [fetchedData]);

	// Ensure orders are synchronised with any changes to charitable donations
	useEffect(() => {
		if (charityState && data.orders) {
			// Order products for selected donations
			let existingCharityOrder = data.orders.find((o) => o.isCharityOrder);
			let newOrderLines = getDonationsAsOrderLines({
				charityProducts: data.charityProducts,
				isGiftAidApplied: charityState.isGiftAidApplied,
				foundationAmount: charityState.donationICASFoundation?.amount,
				scabaAmount: charityState.donationSCABA?.amount
		});

			if (getOrderLinesDiffer(existingCharityOrder, newOrderLines)) {
				let newOrders = getUpdatedOrders(
					data.orders,
					existingCharityOrder,
					newOrderLines
				);
				// Ensure changes to orders are propagated by changing the orders field
				setData({ ...data, orders: newOrders });
			}
		}
	}, [charityState, data]);

	const handleNewDirectDebitRequest = (id: string, value: boolean) => {
		setIsDirty(true);
		if (value) {
			setData({ ...data, directDebitRequestedDate: new Date() });
		} else {
			setData({
				...data,
				directDebitRequestedDate: undefined,
			});
		}
	};

	const handleChangeToPractisingCertificateStatus = (
		newPractisingCertificateStatus: PractisingCertificateChange
	) => {
		setIsDirty(true);

		if (data.isPractisingCertificateHolder) {
			// Request to cancel existing PC, or cancel of that request
			// Toggle PC order line (sorry about the mess!)
			const cancellationRequested = newPractisingCertificateStatus === "Cancel";
			const orders = [...data.orders];
			let keySalesOrder = orders.find((o) => !o.isCharityOrder);
			if (keySalesOrder) {
				let orderLineForPC = keySalesOrder.orderLines.find(
					(l) => l.productCode === "PC" || l.productCode === "PCR"
				);
				if (orderLineForPC) {
					orderLineForPC.isActive = !cancellationRequested;
					if (cancellationRequested) {
						keySalesOrder.outstandingAmount -= orderLineForPC.cost;
					} else {
						keySalesOrder.outstandingAmount += orderLineForPC.cost;
					}
				}
				// Ensure change to orders is propagated
				setData({
					...data,
					orders: orders,
				});
			}
		}
		setData({
			...data,
			practisingCertificateStatus: newPractisingCertificateStatus,
		});
	};

	const handleMultiSelectChange = (
		id: string,
		checked: boolean,
		optionSetArray: OptionSet[]
	) => {
		setIsDirty(true);
		const value = parseInt(id);
		if (checked) {
			const optionSet = findOptionSet(value, optionSetArray);
			if (optionSet) {
				addMultiSelectToState(value, optionSet);
			}
		} else {
			removeMultiSelectFromState(value);
		}
	};

	const addMultiSelectToState = (value: number, optionSet: OptionSet) => {
		const dataCopy = { ...data };
		if (dataCopy.additionalAccountingServices) {
			dataCopy.additionalAccountingServices = [
				...dataCopy.additionalAccountingServices,
				{ name: optionSet?.name, value: value },
			];
		} else {
			dataCopy.additionalAccountingServices = [
				{ name: optionSet?.name, value: value },
			];
		}
		setData(dataCopy);
	};

	const removeMultiSelectFromState = (value: number) => {
		if (data.additionalAccountingServices) {
			const currentOptions = [...data.additionalAccountingServices];
			const optionRemoved = currentOptions.filter(
				(option) => option.value !== value
			);
			setData((prevState) => {
				return {
					...prevState,
					additionalAccountingServices: optionRemoved,
				};
			});
		}
	};

	const handleCheckBoxChange = (id: string, checked: boolean) => {
		const stateName = formatIdForStateName(id);
		setIsDirty(true);
		setData({ ...data, [stateName]: checked });
	};

	const handleRenewalSubmit = () => {
		const submitForm = async () => {
			setSaveInfo({
				saveSent: true,
				saveState: LoadingStatus.IsLoading,
			});
			const submissionData = formatSubmission(data, charityState);
			const { success: saveStatus } = await postData(submissionData);
			if (saveStatus === LoadingStatus.LoadedSuccessfully) {
				setIsDirty(false);
				window.location.href = "/";
			}
			setSaveInfo({
				saveSent: true,
				saveState: saveStatus,
			});
		};
		const invalidField = isInvalidField(
			data.isProfessionalDeclarationSigned,
			data.isPractisingCertificateHolder,
			data.practisingCertificateStatus
		);
		if (invalidField) {
			setInvalidSubmission(true);
			scrollToField(invalidField);
		} else {
			if (isDirty && saveInfo.saveState !== LoadingStatus.IsLoading) {
				submitForm();
			}
		}
	};

	const handleDonationChange = (stateName: string, donation: DonationInfo) => {
		setIsDirty(true);
		setCharityState({
			...charityState,
			[stateName]: donation,
		});
	};

	const handleCharityCheckboxChange = (id: string, value: boolean) => {
		setIsDirty(true);
		const stateName = formatIdForStateName(id);
		setCharityState({
			...charityState,
			[stateName]: value,
		});
	};

	return {
		data,
		handleNewDirectDebitRequest,
		handleChangeToPractisingCertificateStatus,
		handleDonationChange,
		handleCharityCheckboxChange,
		charityState,
		handleMultiSelectChange,
		handleCheckBoxChange,
		saveInfo,
		handleRenewalSubmit,
		invalidSubmission,
	};
};
