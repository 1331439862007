export const renderGBP = (price: number) => {
	const formatter = new Intl.NumberFormat("en-GB", {
		style: "currency",
		currency: "GBP",
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
	return formatter.format(price);
};

export const renderDateString = (dateString: string) => {
	const date = new Date(dateString);
	return date.toLocaleDateString("en-GB");
};

export const getKeyByValue = ({object, value} : { object: Record<string, unknown>, value: unknown}) => {
	return Object.keys(object).find((key) => object[key] === value);
}
