import React, { FC } from "react";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export type PractisingCertificateHolderProps = {
	renewalYear: number;
	cancellationRequested?: boolean;
	handleChange: (requestCancellation: boolean) => void;
};

export const PractisingCertificateHolder: FC<PractisingCertificateHolderProps> = ({
	renewalYear,
	cancellationRequested,
	handleChange
}) => {
	if (cancellationRequested) {
		return (
			<div className="action">
				<p>Your request to stop your practising certificate has been sent.</p>
				<FontAwesomeIcon icon={faCheckCircle} />
				<p>Your subscription will be removed from your renewal for {renewalYear}.</p>
				<p><span onClick={() => handleChange(false)} className="practising-certificate__link" role="button">Cancel request</span></p>
			</div>
		);
	} else {
		return (
			<div className="action">
				<p>Your practising certificate subscription is currently active.</p>
				<FontAwesomeIcon icon={faCheckCircle} />
				<p>A practising certificate is required for engaging in practice. Your certificate is set to automatically renew with your membership each year.</p>
				<p><span onClick={() => handleChange(true)} className="practising-certificate__link" role="button">Stop my practising certificate renewal</span></p>
			</div>
		);
	}
}

