import { Radio } from "@icasdigital/icas.core.reactcomponents";
import React, { FC, useMemo} from "react";
import { OrderProduct } from "../../../types/Product";
import { TextInput } from '@icasdigital/icas.core.reactcomponents';
export type DonationInfo = {
	amount?: number;
	alreadyDonate?: boolean;
};

export type DonationButtonInfo = {
	amount: number;
	id: number;
	labelText: string;
	description?: string;
};

// Find value item matching donation
function findSelectedValueByDonation(
	donationButtons: DonationButtonInfo[],
	donation?: DonationInfo
) {
	if (donation?.amount) {
		return donationButtons.find((button) => donation?.amount === button.amount)
			?.id;
	}
	if (donation?.alreadyDonate) {
		return donationButtons.find((button) => donation?.amount === button.amount)
			?.id;
	}
}

const createDonationFromValue = (value: string | number) => {
	const alreadyDonate = isNaN(parseFloat(value.toString()));

	return {
		alreadyDonate,
		amount: !alreadyDonate ? parseFloat(value.toString()) : undefined
	}
}

type DonationProps = {
	name: string;
	title: string;
	subtitle: string;
	description: JSX.Element;
	website: {
		title: string;
		url: string;
	};
	donationOptions: DonationButtonInfo[];
	donation?: DonationInfo;
	orderLine?: OrderProduct;
	handleChange: (stateName: string, amount: DonationInfo) => void;
};

export const Donation: FC<DonationProps> = ({
	name,
	title,
	subtitle,
	description,
	website,
	donationOptions,
	donation,
	handleChange,
}) => {
	const donationButtons = donationOptions.map((button) => {
		return (
			<Radio.Button
				key={button.id}
				{...button}
				id={`${button.id}-${button.labelText}-option`}
				value={button.amount}
			/>
		);
	});

	// Set the donation amount from selected item
	const handleDonationSelection = (id: string, value: number | string) => {
		const selectedDonation = donationOptions.find(
			(option) => option.amount === parseInt(value.toString())
		);
		if (!selectedDonation) {
			return;
		}
		const donationInfo = createDonationFromValue(selectedDonation.amount);
		handleChange(name, donationInfo);
	};


	const handleDonationAmountChange = (value:string) =>
		handleChange(name, createDonationFromValue(value));

	return (
		<>
			<h3>{title}</h3>
			<Radio
				heading={subtitle}
				name={name}
				handleChange={handleDonationSelection}
				selectedValue={donation?.amount}
			>
				<p>{description}</p>
				<Radio.ButtonContainer vertical style={{gap: "8px"}}>{donationButtons}</Radio.ButtonContainer>
			</Radio>

			<TextInput
				id={`${name}__otherAmount`}
				labelText="I choose a different gift, of &pound;"
				value={donation?.amount ?? ""}
				onChange={(e) => handleDonationAmountChange(e.target.value)}
				placeholder="Enter amount" // Optional placeholder text
			/>
			<p>
				For more information - visit the{" "}
				<a href={website.url} target="_blank" rel="noreferrer">
					{website.title}
				</a>
			</p>
		</>
	);
};
