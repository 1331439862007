import { Button } from "@icasdigital/icas.core.reactcomponents";
import React  from "react";
import { RenewalData } from "../../../types/RenewalData";
import "../card.css";
import { ProfileDetails } from "./ProfileDetails";

type GreetingCardProps = {
	name: string;
	profileLink: string;
	renewalData: RenewalData;
};

const scrollToStart = () => {
	const element = document.getElementById("professional-declaration-card");
	if (element) {
		element.scrollIntoView({ behavior: "smooth" });
	}
};

const subscriptionLinkUrl = "https://icas.com/members/manage-my-membership/subscription/membership-subscription-categories/";

export const GreetingCard = ({
	name,
	profileLink,
	renewalData,
}: GreetingCardProps) => {
	const {
		workingStatusLabel: workingStatus,
		addresses,
		subscriptionCategory: subRate,
		companyName: company,
		companyCity,
		jobTitle,
		isWorking,
		renewalYear,
	} = renewalData;

	return (
		<div className="transparent card">
			<div className="card__content">
				<h2 className="welcome with-line__full">Hi {name}</h2>
				<h2 className="welcome">
					Your Annual Renewal for {renewalYear} is due
				</h2>
				<hr />
				<h3>Your current subscription is:</h3>
				<a
					href={subscriptionLinkUrl}
					target="_blank"
					rel="noreferrer"
					className="price-type"
				>
					{subRate}
				</a>
				<p>
					<a
						href={subscriptionLinkUrl}
						className="underline-a"
						target="_blank"
						rel="noreferrer"
					>
						Find out how we calculate your rate
					</a>
				</p>
				<hr />
				<ProfileDetails
					profileLink={profileLink}
					homeAddressLine1={addresses[0]?.line1}
					homeAddressCity={addresses[0]?.city}
					company={company}
					companyCity={companyCity}
					jobTitle={jobTitle}
					isWorking={isWorking}
					workingStatus={workingStatus}
				/>
				<Button
					id="start"
					text="Get Started"
					style={{ margin: "auto", width: "20em"}}
					onClick={scrollToStart}
				/>
			</div>
		</div>
	);
};
