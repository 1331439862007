import { formatAddressLine, formatNonWorkLine, formatWorkLine } from "../../../utils/SentenceFormatting";


type ProfileDetailsProps = {
	profileLink: string;
	homeAddressLine1?: string;
	homeAddressCity?: string;
	company?: string;
	companyCity?: string;
	jobTitle?: string;
	isWorking?: boolean;
	workingStatus?: string;
};

export const ProfileDetails = ({
	profileLink,
	homeAddressLine1,
	homeAddressCity,
	company,
	companyCity,
	jobTitle,
	isWorking,
	workingStatus
}: ProfileDetailsProps) => {

	const haveAddress = homeAddressLine1 || homeAddressCity;
	const fullWorkDetails = isWorking && (company || companyCity);
	const fullNonWorkingDetails = !isWorking && workingStatus
	const detailsComplete = Boolean(haveAddress && (fullNonWorkingDetails || fullWorkDetails));

	const profileIncompleteLine = <span>{" "}Please <a
			href={profileLink}
			className="underline-a"
			target="_blank"
			rel="noreferrer"
		>
			update your profile
		</a> to ensure we have your complete details</span>;

	const profileCompleteLine = <span>{" "}You can <a
			href={profileLink}
			className="underline-a"
			target="_blank"
			rel="noreferrer"
		>
			update this on your profile
		</a> if your details have changed</span>

	const addressLine = formatAddressLine({homeAddressCity, homeAddressLine1})
	const workLine = isWorking? formatWorkLine({haveAddress, jobTitle, company, companyCity}) : formatNonWorkLine({workingStatus});
	const profileLine = detailsComplete ? profileCompleteLine : profileIncompleteLine;

	return (
		<p>
			{addressLine}
			{workLine}.
			{profileLine}.
		</p>
	)
}