import React, { FC, useContext } from "react";
import { Route, Routes } from "react-router-dom";
import {
	AuthContext,
	LoadingStatus,
	PrivateRoute,
	LoadingLottie,
	Layout,
} from "@icasdigital/icas.core.reactcomponents";

import "./custom.css";
import ErrorBoundary from "./components/ErrorBoundary";
import { JourneyContainer } from "./containers/JourneyContainer";
import { useLogin } from "./hooks/useLogin";
import { ContextProvider } from "./context/ContextProvider";

export const App: FC = (): JSX.Element => {
	const { currentLoading } = useContext(AuthContext);
	const { loginPath } = useLogin();

	let screen = <LoadingLottie style={{ height: "20em", margin: "auto" }} />;

	const isLoaded =
		currentLoading === LoadingStatus.LoadedSuccessfully ||
		currentLoading === LoadingStatus.EndedWithError;

	if (isLoaded) {
		screen = (
			<Routes>
				<Route
					element={
						<PrivateRoute loginPath={loginPath} limitedRoles={["Members"]} />
					}
				>
					<Route path="/*" element={<JourneyContainer />} />
				</Route>
			</Routes>
		);
	}

	return (
		<ContextProvider>
			<ErrorBoundary>
				<Layout>
					<ErrorBoundary>{screen}</ErrorBoundary>
				</Layout>
			</ErrorBoundary>
		</ContextProvider>
	);
};
