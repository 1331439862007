import React, {
	Fragment,
	useCallback,
	useContext,
	useEffect,
	useState,
} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as starOutline } from "@fortawesome/free-regular-svg-icons";
import { faStar as starFilled } from "@fortawesome/free-solid-svg-icons";
import { AuthContext } from "@icasdigital/icas.core.reactcomponents";
import { Context } from "../../../context/ContextProvider";
import { useFetch } from "../../../hooks/useFetch";
import "./feedback-style.css";

const renderButtons = (
	starRating: number | undefined,
	handleStarRatingClick: (value: number) => void
) => {
	return (
		<fieldset className="star-rating__container" id="star-rating">
			<legend>Rate your experience renewing with us today.</legend>
			{[...Array(5)].map((star, index) => {
				const rating = index + 1;
				const { icon, className } =
					starRating && starRating >= rating
						? { icon: starFilled, className: "star filled" }
						: { icon: starOutline, className: "star outline" };

				return (
					<Fragment key={`star ${index}`}>
						<input
							value={rating}
							id={`star${rating}`}
							checked={starRating === rating}
							type="radio"
							name="rating"
							className="screen-reader-only"
							onChange={() => handleStarRatingClick(rating)}
						/>
						<label htmlFor={`star${rating}`}>
							<span className="screen-reader-only">
								{rating} stars out of 5
							</span>
							<FontAwesomeIcon
								key={`star rating ${index}`}
								icon={icon}
								className={className}
								onClick={() => handleStarRatingClick(rating)}
							/>
						</label>
					</Fragment>
				);
			})}
		</fieldset>
	);
};

const useStarRating = () => {
	const [starRating, setStarRating] = useState<number | undefined>(undefined);
	const [buttons, setButtons] = useState<JSX.Element>(<></>);
	const { userId } = useContext(AuthContext);
	const { udpdateStarRatingSubmitted } = useContext(Context);
	const { putData } = useFetch();

	const handleStarRatingClick = useCallback((value: number) => {
		setStarRating(value);
	}, []);

	useEffect(() => {
		const sendRating = async () => {
			udpdateStarRatingSubmitted(true);
			const response = await putData(
				starRating,
				`/renewal/${userId}/feedback/rating`
			);			
			return response;
		};
		if (starRating) {
			sendRating();
			const buttons = renderButtons(starRating, handleStarRatingClick);
			setButtons(buttons);
		} else {
			const buttons = renderButtons(starRating, handleStarRatingClick);
			setButtons(buttons);
		}
	}, [putData, starRating, userId, handleStarRatingClick]);

	return buttons;
};

export const StarRating = () => {
	const buttons = useStarRating();

	return <>{buttons}</>;
};
